import axios from "axios";
import { CONFIG } from "../../config";
import { i18n } from "../../i18n";
import { testWindowSort } from "../../utilities/util";
import { Buffer } from "buffer";
window.Buffer = window.Buffer | Buffer;
import store from "../../store";
import {
  GET_SCORES_FOR_YEAR,
  DOWNLOAD_ISR_PDF,
  GET_ISR_LANGUAGES,
  SET_SORTBY_SUBJECT,
  SET_SHOW_ALLTESTS,
  SET_SUBJECT_FILTER,
  GET_SCORES_DETAIL_REPORT,
  GET_DETAIL_REPORT_IRIS_RESPONSE_ITEM,
  UPDATE_AVAILABLE_STUDENT_SCORES,
  CLEAR_STUDENT_DATA,
  CLEAR_ALL_STUDENT_SCORES,
  GET_STUDENT_SCORES_FROM_BROWSER_CACHE,
  GET_STUDENT_VIDEO_REPORTS,
  GET_CUMULATIVE_HISTORY_SCORES,
  REMOVE_ITEM_SCORES_WITH_CONDITION_CODE,
  GET_METAMETRICS_GROWTH_CHART,
} from "../actions/studentdata";
import { APP_ERROR, HIDE_LOADER, SHOW_LOADER } from "../actions/common";
import LRUCache from "../../models/LRUCache";

const state = {
  studentScores: [],
  fileblob: null,
  isrLanguages: {},
  selectedschoolyear: "",
  sortBySubject: false,
  showAllTests: false,
  selectedSubjects: [],
  studentDetailReportScores: {},
  irisResponse: {},
  allStudentScores: {}, // all student scores by year we have so far,
  eocScores: [],
  cachedDetailedReportScores: new LRUCache({ maxSize: 5 }),
  videoReports: [],
  attemptedToGetVideoReports: false,
  metametricsGrowthChart: {},
};

const getters = {
  studentScores: state => state.studentScores,
  fileblob: state => state.fileblob,
  isrLanguages: state => state.isrLanguages,
  selectedschoolyear: state => state.selectedschoolyear,
  sortBySubject: state => state.sortBySubject,
  showAllTests: state => state.showAllTests,
  selectedSubjects: state => state.selectedSubjects,
  studentDetailReportScores: state => state.studentDetailReportScores,
  irisResponse: state => state.irisResponse,
  allStudentScores: state => state.allStudentScores,
  videoReports: state => state.videoReports,
  eocScores: state => state.eocScores,
  cachedDetailedReportScores: state => state.cachedDetailedReportScores,
  attemptedToGetVideoReports: state => state.attemptedToGetVideoReports,
  metametricsGrowthChart: state => state.metametricsGrowthChart,
};

const actions = {
  /*
    keepCurrentStudentScores: when this flag is set to True, when the user goes through
    the accordion to get data for the historical years and go back to the home page
    the year and scores they selected on the home page before getting to the accordion page
    are left intact
  */
  [GET_SCORES_FOR_YEAR]: ({ commit }, { year, keepCurrentStudentScores = false }) => {
    // const year = payload.year;
    // const keepStudentScores = payload.keepStudentScores || undefined;
    var preview_sinfo = JSON.parse(sessionStorage.getItem("PreviewStudent_Info"));
    return new Promise((resolve, reject) => {
      let cachedData = sessionStorage.getItem(
        "GET_SCORES_FOR_YEAR_" + year + `_${i18n.global.locale}`
      );
      if (cachedData != null) {
        commit(SHOW_LOADER);
        let scoreData = JSON.parse(cachedData);
        commit(GET_SCORES_FOR_YEAR, {
          selectedschoolyear: year,
          scores: scoreData,
          keepCurrentStudentScores: keepCurrentStudentScores,
        });

        commit(UPDATE_AVAILABLE_STUDENT_SCORES, {
          year: year,
          scores: scoreData,
        });
        commit(HIDE_LOADER);

        return resolve(scoreData);
      }
      let token = sessionStorage.getItem("jwttoken") || "";
      let pdata = null;
      if (preview_sinfo != null && preview_sinfo.preview)
        pdata = {
          SelectedSchoolYear: parseInt(year),
          Preview: preview_sinfo.preview,
          waveid: preview_sinfo.waveid,
        };
      else pdata = { SelectedSchoolYear: parseInt(year) };

      commit(SHOW_LOADER);
      axios({
        url: CONFIG.BACKEND_URL + `api/${i18n.global.locale}/report/studentscores`,
        data: pdata,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(resp => {
          commit(GET_SCORES_FOR_YEAR, {
            selectedschoolyear: pdata.SelectedSchoolYear,
            scores: resp.data,
            keepCurrentStudentScores: keepCurrentStudentScores,
          });

          commit(UPDATE_AVAILABLE_STUDENT_SCORES, {
            year: pdata.SelectedSchoolYear,
            scores: resp.data,
          });

          commit(HIDE_LOADER);

          resolve(resp.data);
        })
        .catch(err => {
          commit(APP_ERROR, err);
          reject(err);
        });
    });
  },

  [DOWNLOAD_ISR_PDF]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem("jwttoken") || "";
      let pdata = {
        OppKey: data.oppkey,
        LanguageKey: data.languageKey,
        MultiSubjectPdf: data.multiSubjectPdf,
        UseGeneratedPdfFallback: data.useGeneratedPdfFallback,
      };
      axios({
        url: CONFIG.BACKEND_URL + "api/report/downloadisr",
        data: pdata,
        method: "POST",
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(resp => {
          var reader = new FileReader();
          reader.onload = function () {
            resp.data = new Blob([new Buffer(reader.result, "base64")], {
              type: "application/pdf",
            });
            let pdata = {
              oppKey: data.oppkey,
              filename: data.filename,
              data: resp.data,
            };
            commit(DOWNLOAD_ISR_PDF, pdata);
            resolve(pdata);
          };
          reader.readAsText(resp.data);
        })
        .catch(err => {
          commit(APP_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_ISR_LANGUAGES]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem("jwttoken") || "";
      let pdata = { OppKey: data.oppkey };
      axios({
        url: CONFIG.BACKEND_URL + "api/report/IsrLanguages",
        data: pdata,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(resp => {
          commit(GET_ISR_LANGUAGES, resp.data);
          resolve(pdata);
        })
        .catch(err => {
          commit(APP_ERROR, err);
          reject(err);
        });
    });
  },
  [SET_SORTBY_SUBJECT]: ({ commit }) => {
    commit(SET_SORTBY_SUBJECT);
  },
  [SET_SHOW_ALLTESTS]: ({ commit }) => {
    commit(SET_SHOW_ALLTESTS);
  },
  [SET_SUBJECT_FILTER]: ({ commit }, subjects) => {
    commit(SET_SUBJECT_FILTER, subjects);
  },

  [GET_SCORES_DETAIL_REPORT]: ({ commit, state }, data) => {
    return new Promise((resolve, reject) => {
      let cacheKey = `${GET_SCORES_DETAIL_REPORT}_${data.oppkey}_${i18n.global.locale}`;
      let sessionStorageKey = state.cachedDetailedReportScores.get(cacheKey);
      let cachedData = sessionStorage.getItem(sessionStorageKey);
      if (sessionStorageKey !== -1 && cachedData) {
        let scoreData = JSON.parse(cachedData);
        commit(GET_SCORES_DETAIL_REPORT, { detailScores: scoreData, cacheHit: true });
        return resolve(scoreData);
      }
      // If we don't have the cached data, get it from the API and cache it
      let token = sessionStorage.getItem("jwttoken") || "";
      commit(SHOW_LOADER);
      axios({
        url: CONFIG.BACKEND_URL + `api/${i18n.global.locale}/report/getdetailedreportdata`,
        data: data,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(resp => {
          commit(GET_SCORES_DETAIL_REPORT, { detailScores: resp.data, cacheHit: false });
          commit(REMOVE_ITEM_SCORES_WITH_CONDITION_CODE);
          commit(HIDE_LOADER);
          resolve(resp.data);
        })
        .catch(err => {
          commit(APP_ERROR, err);
          reject(err);
        });
    });
  },

  [GET_DETAIL_REPORT_IRIS_RESPONSE_ITEM]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem("jwttoken") || "";
      //let pdata = { OppKey : data.oppkey};
      axios({
        url: CONFIG.BACKEND_URL + "api/report/createresourcecontent",
        data: data,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(resp => {
          commit(GET_DETAIL_REPORT_IRIS_RESPONSE_ITEM, {
            irisResponse: resp.data,
          });
          resolve(resp.data);
        })
        .catch(err => {
          commit(APP_ERROR, err);
          reject(err);
        });
    });
  },

  [UPDATE_AVAILABLE_STUDENT_SCORES]: ({ commit }, { year, scores }) => {
    commit(UPDATE_AVAILABLE_STUDENT_SCORES, { year: year, scores: scores });
  },

  [CLEAR_STUDENT_DATA]: ({ commit }) => {
    commit(CLEAR_STUDENT_DATA);
  },

  [CLEAR_ALL_STUDENT_SCORES]: ({ commit }) => {
    commit(CLEAR_ALL_STUDENT_SCORES);
  },

  /**
   * Populate state.allStudentScores with scores in the selected language from
   * the browser cache
   * @param {*} param0
   */
  [GET_STUDENT_SCORES_FROM_BROWSER_CACHE]: ({ commit }) => {
    let cachedKeys = Object.keys(sessionStorage);
    const languageKey = `_${i18n.global.locale}`;
    cachedKeys.forEach(cachedKey => {
      if (cachedKey.includes("GET_SCORES_FOR_YEAR_") && cachedKey.includes(languageKey)) {
        let year = cachedKey.replace("GET_SCORES_FOR_YEAR_", "").replace(languageKey, "");
        year = parseInt(year, 10);
        commit(UPDATE_AVAILABLE_STUDENT_SCORES, {
          year: year,
          scores: JSON.parse(sessionStorage.getItem(cachedKey)),
        });
      }
    });
  },

  [GET_STUDENT_VIDEO_REPORTS]: async ({ commit }) => {
    commit(SHOW_LOADER);
    let token = sessionStorage.getItem("jwttoken") || "";
    const response = await axios.get(CONFIG.BACKEND_URL + "api/videoreports", {
      headers: { Authorization: `Bearer ${token}` },
    });
    let sortedVideos = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
    commit(HIDE_LOADER);
    commit(GET_STUDENT_VIDEO_REPORTS, sortedVideos);
  },

  [GET_CUMULATIVE_HISTORY_SCORES]: ({ commit, rootState }, { studentScores }) => {
    return new Promise((resolve, reject) => {
      const res = { eocScores: [] };
      const allSchoolYears = rootState.auth.studentinfo.schoolYears.sort();
      const mostRecentSchoolYear = allSchoolYears[allSchoolYears.length - 1];
      const cachedData = sessionStorage.getItem(
        `${GET_CUMULATIVE_HISTORY_SCORES}_${i18n.global.locale}`
      );
      if (cachedData != null) {
        res.eocScores = JSON.parse(cachedData);
        commit(GET_CUMULATIVE_HISTORY_SCORES, res);
        resolve(res);
      } else if (studentScores && Array.isArray(studentScores) && studentScores.length > 0) {
        // Make a copy of studentScores then sort it because we don't want Vue to be stuck in an infinite loop
        // thinking the studentScores state was changed due to it being sorted (the state studentScores is watched in mutliple components)
        let scoresWithEocData = studentScores
          .filter(score => score.hasEocCumulativeHistory)
          .sort(testWindowSort);

        if (!scoresWithEocData.length) return;

        let token = sessionStorage.getItem("jwttoken") || "";
        commit(SHOW_LOADER);
        axios({
          url: CONFIG.BACKEND_URL + `api/${i18n.global.locale}/report/getdetailedreportdata`,
          data: {
            oppkey: scoresWithEocData[0].oppKey,
            schoolYear: mostRecentSchoolYear,
          },
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
        })
          .then(resp => {
            res.eocScores = resp?.data?.eochistoricdata?.historicdata || [];
            commit(GET_CUMULATIVE_HISTORY_SCORES, res);
            commit(HIDE_LOADER);
            resolve(res);
          })
          .catch(err => {
            commit(APP_ERROR, err);
            reject(err);
          });
      }
    });
  },

  [GET_METAMETRICS_GROWTH_CHART]: ({ commit }, { oppKey, subject, isLexile, language }) => {
    commit(GET_METAMETRICS_GROWTH_CHART, {});
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem("jwttoken") || "";
      axios({
        url: CONFIG.BACKEND_URL + "api/chart/metametrics/growth",
        params: { oppKey, subject, isLexile, language },
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(resp => {
          commit(GET_METAMETRICS_GROWTH_CHART, {
            chart: resp.data.chart,
            loaded: true,
          });
          resolve(resp.data);
        })
        .catch(err => {
          commit(APP_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  [GET_SCORES_FOR_YEAR]: (state, res) => {
    sessionStorage.setItem(
      "GET_SCORES_FOR_YEAR_" + res.selectedschoolyear + `_${i18n.global.locale}`,
      JSON.stringify(res.scores)
    );

    // We don't update studentScores when the users click around in the accordion page
    // so that when they return to Home, they year + scores for that year stay intact
    // If keepCurrentStudentScores is True, we return early and don't update studentScores

    if (res.keepCurrentStudentScores) {
      return;
    }
    state.studentScores = [];
    const months = [
      "January",
      "February",
      "March",
      "Spring",
      "April",
      "May",
      "Summer",
      "June",
      "July",
      "August",
      "Fall",
      "September",
      "October",
      "November",
      "December",
    ];
    if (
      res.scores[0]?.dateTaken.includes("0001-01-01") &&
      store.getters.getClientConfig.clientname &&
      store.getters.getClientConfig.clientname.toLowerCase() == "texas"
    ) {
      let sortedScores = res.scores
        .sort((a, b) => {
          a = a.testReason.split(" ");
          b = b.testReason.split(" ");
          return a[1] - b[1] || months.indexOf(a[0]) - months.indexOf(b[0]);
        })
        .reverse();
      state.studentScores = [...sortedScores];
    } else {
      let dateTakenHasValue = true;
      let sortedScores = res.scores;
      for (const obj of sortedScores) {
        if (obj === null || obj.dateTaken === null) {
          dateTakenHasValue = false;
          break;
        }
      }
      if (dateTakenHasValue) {
        sortedScores = res.scores.sort((a, b) => {
          return new Date(b.dateTaken) - new Date(a.dateTaken);
        });
      }
      state.studentScores = [...sortedScores];
    }

    // state.selectedschoolyear = res.selectedschoolyear;
  },
  [DOWNLOAD_ISR_PDF]: (state, res) => {
    state.fileblob = res;
  },

  [GET_ISR_LANGUAGES]: (state, res) => {
    state.isrLanguages = res;
  },

  [SET_SORTBY_SUBJECT]: state => {
    state.sortBySubject = !state.sortBySubject;
  },

  [SET_SHOW_ALLTESTS]: state => {
    state.showAllTests = !state.showAllTests;
  },

  [SET_SUBJECT_FILTER]: (state, subjects) => {
    state.selectedSubjects = subjects;
  },

  [GET_SCORES_DETAIL_REPORT]: (state, res) => {
    let cacheKey = `${GET_SCORES_DETAIL_REPORT}_${res.detailScores.oppkey}_${i18n.global.locale}`;
    if (!res.cacheHit) state.cachedDetailedReportScores.put(cacheKey);
    sessionStorage.setItem(cacheKey, JSON.stringify(res.detailScores));
    state.studentDetailReportScores = res.detailScores;
  },

  [GET_DETAIL_REPORT_IRIS_RESPONSE_ITEM]: (state, res) => {
    //sessionStorage.setItem("GET_SCORES_DETAIL_REPORT", JSON.stringify(res.detailScores));
    state.irisResponse = res.irisResponse;
  },

  [UPDATE_AVAILABLE_STUDENT_SCORES]: (state, payload) => {
    let schoolYear = payload.year;
    let scores = payload.scores;

    if (scores == null) return;
    if (Array.isArray(scores) && !scores.length) return;

    if (schoolYear) {
      let subjects = scores.map(score => {
        return score.subject;
      });
      state.allStudentScores[schoolYear] = {
        subjects: new Set(subjects),
        opps: scores,
      };
    }

    // Create a new object and copy over the properties to trigger Vue's reactivity
    // Link for reference: https://v2.vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
    let newStudentScores = Object.assign({}, state.allStudentScores);
    state.allStudentScores = newStudentScores;
  },

  [GET_CUMULATIVE_HISTORY_SCORES]: (state, res) => {
    if (res.eocScores != null) {
      sessionStorage.setItem(
        `${GET_CUMULATIVE_HISTORY_SCORES}_${i18n.global.locale}`,
        JSON.stringify(res.eocScores)
      );
    }
    state.eocScores = res.eocScores;
  },

  [CLEAR_ALL_STUDENT_SCORES]: state => {
    state.allStudentScores = {};
  },

  [CLEAR_STUDENT_DATA]: state => {
    (state.studentScores = []),
      (state.fileblob = null),
      (state.isrLanguages = {}),
      (state.selectedschoolyear = ""),
      (state.sortBySubject = false),
      (state.showAllTests = false),
      (state.selectedSubjects = []),
      (state.studentDetailReportScores = {}),
      (state.irisResponse = {}),
      (state.eocScores = {}),
      (state.cachedDetailedReportScores = new LRUCache({ maxSize: 5 })),
      ((state.allStudentScores = {}),
      (state.videoReports = []),
      (state.attemptedToGetVideoReports = false)),
      (state.metametricsGrowthChart = { loaded: false });
  },

  [GET_STUDENT_VIDEO_REPORTS]: (state, videoReports) => {
    state.videoReports = videoReports;
    state.attemptedToGetVideoReports = true;
  },

  [REMOVE_ITEM_SCORES_WITH_CONDITION_CODE]: state => {
    if (!state.studentScores || !state.studentDetailReportScores) return;
    // Get overall score for the current studentDetailReportScores
    let overallScore = state.studentScores.find(
      score => score.oppKey === state.studentDetailReportScores.oppkey
    );
    if (
      !overallScore ||
      !overallScore.testType ||
      !(overallScore.testType.toLowerCase() === "telpas") ||
      !state.studentDetailReportScores.itemscores
    )
      return;

    // Parent categories have parentid == null
    let parentCategoryIdsWithNoConditionCode = new Set(
      state.studentDetailReportScores.categoryscores
        .filter(catScore => !catScore.parentid && !catScore.conditioncodetext)
        .map(cat => cat.categoryid)
    );

    // Get the sub (non-parent) category ids with NO condition code. These
    // are the category ids that match with the item scores' category ids
    let categoryIdsWithNoConditionCode = new Set(
      state.studentDetailReportScores.categoryscores
        .filter(
          catScore =>
            catScore.parentid && parentCategoryIdsWithNoConditionCode.has(catScore.parentid)
        )
        .map(cat => cat.categoryid)
    );

    state.studentDetailReportScores.itemscores = state.studentDetailReportScores.itemscores.filter(
      itemScore =>
        categoryIdsWithNoConditionCode.has(itemScore.categoryid) ||
        parentCategoryIdsWithNoConditionCode.has(itemScore.categoryid)
    );
  },

  [GET_METAMETRICS_GROWTH_CHART]: (state, growthChart) => {
    state.metametricsGrowthChart = growthChart;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
